import React from "react";
import classnames from "classnames";
import QuotationMarkGray from "../images/quotation-mark_gray.svg";
import QuotationMarkWhite from "../images/quotation-mark_wht.svg";

const Quote = ({ dark, quote, name, company }) => {
  return (
    <figure
      className={classnames("quotebox", { "border-secondary-light": dark })}
    >
      {dark ? (
        <QuotationMarkWhite className="quotation-mark dark" />
      ) : (
        <QuotationMarkGray className="quotation-mark" />
      )}
      <blockquote
        className={classnames(
          "blockquote",
          { "text-white": dark },
          { "mb-0": !name && !company }
        )}
      >
        {quote}
      </blockquote>
      
      {name || company ? (
        <figcaption
          className={classnames("blockquote-footer", { "text-white": dark })}
        >
          {name} - &nbsp;
          <cite
            className={classnames({
              "text-success": !dark,
              "text-secondary-light": dark,
            })}
            title="Source Title"
          >
            {company}
          </cite>
        </figcaption>
      ) : null}
    </figure>
  );
};

export default Quote;
