import React from "react";
import {Link} from "gatsby"
import { StaticImage } from "gatsby-plugin-image";

const PortfolioFeed = ({className}) => (
  <section className={className}>
    <div className="container">
      <div className="row">
        <div className="col">
          <h3>Our work</h3>
        </div>
      </div>
      <div className="row">
        <div className="col d-flex flex-wrap portfolio-feed-3">
          <div className="portfolio-item mb-4">
            <div className="card linked h-100">
              <Link className="d-flex flex-column h-100"to="/portfolio/jus-workshop/">
                <div className="card-body flex-grow-1 flex-fill pb-6">
                  <h2>Jus Workshop</h2>
                  <p className="text-dark">Custom Development, Integrations, UI/UX Services</p>
                </div>
                <div className="card-footer bg-transparent border-0 p-0  flex-shrink-0">
                  <StaticImage src="../images/portfolio/jus/workshop-hero.png" alt="JUS WORKSHOP" />
                </div>
              </Link>
            </div>
          </div>

          <div className="portfolio-item mb-4">
            <div className="card linked h-100">
              <Link className="d-flex flex-column h-100"to="/portfolio/eridan/">
                <div className="card-body flex-grow-1 flex-fill pb-6">
              <h2>Eridan</h2>
              <p className="text-dark">Logo & Identity Design, UI/UX Services, Website Design</p>
                </div>
                <div className="card-footer bg-transparent border-0 p-0  flex-shrink-0">
                  <StaticImage src="../images/portfolio/eridan/eridan-showcase.png" alt="ERIDAN" />
                </div>
              </Link>
            </div>
          </div>

          <div className="portfolio-item mb-4">
            <div className="card linked h-100">
              <Link className="d-flex flex-column h-100"to="/portfolio/cg-custom-guide/">
                <div className="card-body flex-grow-1 flex-fill pb-6">
              <h2>CG Custom Guide</h2>
              <p className="text-dark">UI/UX Services, Website Design</p>
                </div>
                <div className="card-footer bg-transparent border-0 p-0  flex-shrink-0">
                <StaticImage src="../images/portfolio/cg-custom-guide/cg-custom-guide-featured.png" alt="CG CUSTOM GUIDE" />
            </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default PortfolioFeed;
