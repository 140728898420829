import React from "react";
import {Link} from 'gatsby';
import Layout from "../../components/layout";
import Seo from "../../components/seo"
import {StaticImage} from "gatsby-plugin-image";
import Quote from "../../components/quote";
import ContactForm from "../../components/contact-form";
import PortfolioFeed from "../../components/portfolio-feed";
import ScrollContainer from "react-indiana-drag-scroll";


const ProjectForm = ({location}) => (
  <section className="bg-light py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2>Have a Project?</h2>
          <p className="pr-5">We'd love to hear about it!</p>
          <small className="text-muted pr-5">
            Email us at{" "}
            <a href="mailto:solicitations@729solutions.com">
              solicitations@729solutions.com
            </a>{" "}
            for solicitations regarding partnerships, linkbacks, or
            subcontracting opportunities.
          </small>
        </div>
        <div className="col-12 col-lg-6">
          <ContactForm showHowYouFindUs={false} buttonText="Submit" location={location}/>
        </div>
      </div>
    </div>
  </section>
);
const MarketResearchIcon = () => (
  <svg
    className="text-muted"
    width={50}
    height={50}
    id="ea8c11b2-3e00-4825-b150-55c04cc12fa5" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 144 144">
    <g id="f1c4d81c-7f94-4ebe-9ccf-aae8eb9c4e78" data-name="icon market research">
      <g id="aa72a6cc-799b-4432-a1fe-fe09dd318b05" data-name="Rectangle 5">
        <polygon
          points="109.2 116.09 109.07 27.54 113.68 27.54 123.35 27.52 123.5 130.36 34.95 130.49 34.93 116.2 109.2 116.09"
          fill="none" stroke="currentColor" stroke-width="3"/>
      </g>
      <g id="bfb60981-fe2c-4e48-ba4b-49824b088f5b" data-name="Rectangle 4">
        <rect x="20.57" y="13.33" width="88.55" height="102.83" transform="translate(-0.1 0.1) rotate(-0.09)"
              fill="none" stroke="currentColor" stroke-width="3"/>
      </g>
      <path id="b73c334a-9cb0-46e1-bb66-c1d686f1bd18" data-name="Path 1" d="M39.17,35.2l50.45-.08" fill="none"
            stroke="currentColor" stroke-width="3"/>
      <path id="af69587f-98a7-4f49-a69e-9f1866dc4eda" data-name="Path 2" d="M39.19,49.48l50.45-.07" fill="none"
            stroke="currentColor" stroke-width="3"/>
      <path id="a977453a-f370-43d4-a2e5-8ec656b771e9" data-name="Path 3" d="M39.22,63.76l50.44-.07" fill="none"
            stroke="currentColor" stroke-width="3"/>
      <path id="f0b8c167-147f-4699-910e-daaa7e63153c" data-name="Path 4" d="M39.24,78,89.68,78" fill="none"
            stroke="currentColor" stroke-width="3"/>
      <path id="f325ef75-aa24-41af-adbb-1aae609adb36" data-name="Path 5" d="M39.26,92.33l38-.06" fill="none"
            stroke="currentColor" stroke-width="3"/>
    </g>
  </svg>
)

const UserFlowIcon = () => (
  <svg
    className="text-muted"
    width={50}
    height={50}
    id="b04abcf6-915e-4101-a30d-579f98f82595" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 144 144">
    <g id="a9715317-253e-4e2a-9b8f-e42433a04d67" data-name="icon user flow">
      <g id="bbf3f5c3-462d-4c27-ab52-ff2656d3f399" data-name="Ellipse 18">
        <circle cx="25.64" cy="70.7" r="11.09" fill="none" stroke="currentColor" stroke-width="3"/>
      </g>
      <g id="a89ccc62-2133-43c4-9962-3bf96806c48b" data-name="Ellipse 19">
        <circle cx="118.36" cy="72.51" r="11.09" fill="none" stroke="currentColor" stroke-width="3"/>
      </g>
      <path id="a682776c-d2f6-4f16-bbb7-868e47900dc3" data-name="Path 6"
            d="M36,76.05s17.62,7.16,37.23-6.49,35-3.53,35-3.53" fill="none" stroke="currentColor" stroke-width="3"
            stroke-dasharray="7.39"/>
    </g>
  </svg>
)
const WorkShopIcon = () => (
  <svg
    className="text-muted"
    width={50}
    height={50}
    id="ee7e9fed-9daa-45b7-ba4b-6c49211f9444" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 144 144">
    <g id="a0458291-d568-4b73-a2b5-4801893d37ea" data-name="Group 16">
      <g id="a8bd378b-6d20-41bc-90fe-0e6b4c1d553e" data-name="Ellipse 20-4">
        <circle cx="116.06" cy="60.59" r="9.9" fill="none" stroke="currentColor" stroke-width="3"/>
      </g>
      <path id="a8f3471d-d9a2-4501-9d43-019f25d5033b" data-name="Path 7-4"
            d="M98.31,94.64s1.74-20,18.65-19.8,16.73,19.8,16.73,19.8" fill="none" stroke="currentColor"
            stroke-width="3"/>
    </g>
    <g id="e02afa2a-58d7-4750-8600-bedf5c8f43c1" data-name="Group 17">
      <g id="a238f9a9-5e1b-4d3d-9a86-ff6c1cb1bfa3" data-name="Ellipse 20-5">
        <circle cx="28.05" cy="60.59" r="9.9" fill="none" stroke="currentColor" stroke-width="3"/>
      </g>
      <path id="ae8de4eb-f6c8-4d3e-9cc4-ca6a2ce2a09c" data-name="Path 7-5"
            d="M10.31,94.64s1.74-20,18.64-19.8,16.74,19.8,16.74,19.8" fill="none" stroke="currentColor"
            stroke-width="3"/>
    </g>
    <g id="b205e476-3371-4b77-8abe-8d14ec116b03" data-name="Rectangle 6">
      <path d="M57.63,52.36v5h-5v-5h5m3-3h-11v11h11v-11Z" fill="currentColor"/>
    </g>
    <g id="e4da75a9-ae96-411b-873b-98441f0b339c" data-name="Rectangle 11">
      <path d="M57.63,74.36v5h-5v-5h5m3-3h-11v11h11v-11Z" fill="currentColor"/>
    </g>
    <g id="a7f70bc5-7b12-464f-bce1-55e3b0396456" data-name="Rectangle 7">
      <path d="M73,52.36v5H68v-5h5m3-3H65v11H76v-11Z" fill="currentColor"/>
    </g>
    <g id="b3328c6d-08e0-4352-a939-cac74a4aecb0" data-name="Rectangle 10">
      <path d="M73,67.76v5H68v-5h5m3-3H65v11H76v-11Z" fill="currentColor"/>
    </g>
    <g id="b85501a4-f473-4f97-a14a-89b890fff3b6" data-name="Rectangle 8">
      <path d="M88.43,52.36v5h-5v-5h5m3-3h-11v11h11v-11Z" fill="currentColor"/>
    </g>
    <g id="b5654b08-21dc-44a5-a1c2-175bae3051a0" data-name="Rectangle 9">
      <path d="M88.43,67.76v5h-5v-5h5m3-3h-11v11h11v-11Z" fill="currentColor"/>
    </g>
    <g id="bccf4931-d6f8-476d-87b3-09e8b65abd43" data-name="Rectangle 12">
      <path d="M88.43,83.16v5h-5v-5h5m3-3h-11v11h11v-11Z" fill="currentColor"/>
    </g>
  </svg>
)

const Index = ({location}) => {

  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Alta FoodCraft"
        description="We developed a dashboard and customer portal that provides a customized user experience."
      />
      <section className="bg-dark">
        <div className="container py-6">
          <div className="row">
            <div className="col-lg-4">
              <h4 className="text-secondary-light">Client</h4>
              <p className="text-white">
                AltaFoodcraft & Associated Services</p>
              <h4 className="text-secondary-light">Our Role</h4>
              <p className="text-white">
                UI/UX Consulting
                <br></br>
                Website Design Consulting
                <br></br>
                Dashboard Design
                <br></br>
                Custom Software
                Development
              </p>
            </div>
            <div className="col-lg-8 mt-5 mt-lg-0">
              <h4 className="text-secondary-light">Summary</h4>
              <h2 className="text-white">729 SOLUTIONS DEVELOPED A DASHBOARD AND CUSTOMER PORTAL THAT PROVIDES A
                CUSTOMIZED USER EXPERIENCE.</h2>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-6">
          <div className="row justify-content-between">
            <div className="col-lg-2">
              <StaticImage src="../../images/portfolio/alta-food-cart/dashboard_mockups_mobile_0_id.png"
                           alt="Alta Food Cart Dashboard 1"/>
            </div>
            <div className="col-lg-2 d-none d-lg-block">
              <StaticImage src="../../images/portfolio/alta-food-cart/dashboard_mockups_mobile_1_id.png"
                           alt="Alta Food Cart Dashboard 2"/>
            </div>
            <div className="col-lg-2 d-none d-lg-block">
              <StaticImage src="../../images/portfolio/alta-food-cart/dashboard_mockups_mobile_2_id.png"
                           alt="Alta Food Cart Dashboard 3"/>
            </div>
            <div className="col-lg-2 d-none d-lg-block">
              <StaticImage src="../../images/portfolio/alta-food-cart/dashboard_mockups_mobile_3_id.png"
                           alt="Alta Food Cart Dashboard 4"/>
            </div>
            <div className="col-lg-2 d-none d-lg-block">
              <StaticImage src="../../images/portfolio/alta-food-cart/dashboard_mockups_mobile_4_id.png"
                           alt="Alta Food Cart Dashboard 5"/>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-6">
          <div className="row">
            <div className="col-lg-6">
              <h2>Problem Statement</h2>
              <p><span>AltaFoodcraft</span> and its sister company, <span>Associated Services</span>,
                wanted to join forces to create a custom experience for business clients. Their existing platform wasn’t
                meeting their expectations, had an old-fashioned user interface and didn’t provide the analytics they
                needed.</p>
              <p>They needed a user experience consultant to create a site experience that supported
                educated buying decisions.&nbsp;</p>
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0">
              <h2>Project Goals</h2>
              <ol className="text-primary m-0 pl-2">
                <ol>
                  <li><span className="text-dark">Determine the full scope of the project.</span></li>
                  <li><span className="text-dark">Phase the approach.</span></li>
                  <li><span className="text-dark">Design an intuitive, easy-to-use UX.</span></li>
                  <li><span
                    className="text-dark">Develop a custom dashboard that’s flexible enough for future growth.</span>
                  </li>
                  <li><span className="text-dark">Integrate the website with the client’s existing CRM.</span></li>
                </ol>
              </ol>

            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-6">
          <div className="row">
            <div className="col-lg-6">
              <StaticImage
                src="../../images/portfolio/alta-food-cart/dashboard_mockups_desk_0_id.png"
                alt="Alta Food Cart Desktop Dashboard 1"/>
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0">
              <StaticImage
                src="../../images/portfolio/alta-food-cart/dashboard_mockups_desk_1_id.png"
                alt="Alta Food Cart Desktop Dashboard  2"/>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container pb-6">
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <div className="mb-4">
                <h2 className="pb-3 m-0">Development Languages Used</h2>
                <p>
                  JavaScript
                  <br></br>
                  Ruby
                  <br></br>
                  HTML 5
                  <br></br>
                  CSS 3
                </p>
              </div>
              <div className="mb-4">
                <h2 className="pb-3 m-0">Tools used</h2>
                <p>AWS</p>
              </div>
              <div className="mb-4">
                <h2 className="pb-3 m-0">Frameworks Used</h2>
                <p>
                  Ruby on Rails
                  <br></br>
                  Bootstrap
                </p>
              </div>
              <div className="mb-4">
                <h2 className="pb-3 m-0">Databases</h2>
                <p>MySQL</p>
              </div>
              <div className="mb-4">
                <h2 className="pb-3 m-0">Other</h2>
                <p>External API Integration (SAP Business 1)</p>
              </div>
            </div>
            <div className="col-sm-12 col-lg-6">
              <div className="mb-4">
                <h2 className="pb-3 m-0">Software Used</h2>
                <p>
                  <StaticImage src="../../images/tools/adobe_xd.png"
                               alt="Adobe XD Icon"/>
                </p>
              </div>
              <div className="mb-4">
                <h2 className="pb-3 m-0">Research Methods</h2>
                <p className="d-flex align-items-center">
                  <MarketResearchIcon/>
                  <span className="pl-4"> Market research analysis</span>
                </p>
                <p className="d-flex align-items-center">
                  <UserFlowIcon/>
                  <span className="pl-4">User Flow & Journey Mapping</span>
                </p>
                <p className="d-flex align-items-center">
                  <WorkShopIcon/>
                  <span className="pl-4">Design Workshops</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <StaticImage src="../../images/portfolio/alta-food-cart/dashboard_mockups_desk_2_id.png"
                           alt="Alta Food Cart Desktop Dashboard 1"/>
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0">
              <StaticImage src="../../images/portfolio/alta-food-cart/dashboard_mockups_desk_3_id.png"
                           alt="Alta Food Cart Desktop Dashboard  2"/>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-6">
          <div className="row">
            <div className="col-lg-6">
              <h2>key focus areas</h2>
              <p>What are the challenges or barriers to use in the industry?</p>
              <p>What kinds of features and functionality are available for each persona type?</p>
              <p>How can we handle large differences in information type in different components?</p>
              <p>How can we use a single code base for both businesses with different brand identities?</p>
              <p>What information is available to us from the client’s existing CMS?</p>
              <p>What can we do for customers that is better than the client’s competitors?</p>
              <p>How can we provide an all-encompassing <Link className="inlinks"
                                                              href="/user-experience-consultant-services/">experience
                with room for growth</Link>?</p>
            </div>
            <div className="col-lg-6 mt-5 mt-lg-0">
              <h2>Core client needs</h2>
              <div className="row">
                <div className="col-lg-6">
                  <p><b>Understanding the cause</b></p>
                  <p>The customer needed UI/UX consultants and <Link className="inlinks"
                                                                     href="/web-design-consulting-services/">web
                    design consultants who understood their cause</Link>.</p>
                  <p><b>Larger vision</b></p>
                  <p>In terms of web design, they had a larger vision for the project that included two separate, but
                    sister, businesses. Their user experience consultant needed to understand and explain a larger
                    vision for the project and the scope of the two businesses’ long-term needs.</p>
                </div>
                <div className="col-lg-6">
                  <p><b>Tech collaboration</b></p>
                  <p>The website design consultant and UI/UX lead needed to work collaboratively with their team.</p>
                  <p><b>Phased implementation</b></p>
                  <p>The client needed the roll-out to happen over time.</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section>
        <ScrollContainer vertical={false} className="scroll-carousel">
          <StaticImage
            layout="constrained"
            height="650"
            src="../../images/portfolio/alta-food-cart/dashboard_for_portfolio.png"
            alt="Dashboard For Portfolio"
            className="scroll-carousel-item"
            imgClassName="scroll-carousel-img"
          />
        </ScrollContainer>
      </section>
      <section>
        <div className="container py-6">
          <div className="row">
            <div className="col-lg-6">
              <h2>729 Solutions created a dashboard and user interface that provided a natural and educational user flow
                for the client’s customers. </h2>
            </div>
            <div className="col-lg-6">
              <h2>What we learned</h2>
              <p>Our clients at AltaFoodcraft &amp; Associated Services are intelligent, business-minded and
                detail-oriented people who care about their customers’ needs. We learned that when clients become active
                participants in their project, it helps immensely with the creation of a solution that is not only
                useful but developmentally lightweight and flexible enough for future phases.</p>
              <p>In addition, we put extra effort into structuring our design process for a collaborative working
                environment in Adobe XD. This means multiple graphic designers can work simultaneously to make the
                design process go extremely fast.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-dark">
        <div className="container py-6">
          <div className="row">
            <div className="col-lg-6">
              <Quote
                dark
                company="AltaFoodcraft"
                name="Stuart Harris"
                quote="They do a good job of setting the right expectations. The fact that we are coming back time and again is a testimony!"
              />
            </div>
            <div className="col-lg-6 d-flex flex-column align-items-center mt-6">
              <div className="row">
                <div className="col">
                  <StaticImage src="../../images/portfolio/alta-food-cart/associated_logo.png"
                               alt=""/>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <StaticImage src="../../images/portfolio/alta-food-cart/logo.png"
                               alt=""/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProjectForm location={location}/>
      <PortfolioFeed className="my-6"/>
    </Layout>
  );
};

export default Index;
